import revive_payload_client_scZaUWx3Nz from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZCwzV2BUil from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gse8FE6AVk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fIiXlksfSe from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.21.0_vite@5.4.1_@types+node@22.4.1_sass@1.77._x2rarvwqvrmgyxybeiwck2zpwe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_QICp2vvHbx from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BRJ5Fr170B from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LTsCME5eDr from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NREDdPVrPj from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jSmnvxCknW from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_UnCDV6m3hT from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Qqx7zxMt5s from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node_jtbpmokwl5756gqy2eitzupdt4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_mmuokmx30g from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.4_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_g82ZZPjvKG from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.0_@vite-pwa+assets-generator@0.2.4_magicast@0.3.4_rollup@4.21.0_vite@5.4._ygk5525fjuunsx5lrafzbbgaui/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_kjBwD4n3Dq from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_magicast@0.3.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_nRxBUrOLuB from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_IXfR5wyQPD from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
export default [
  revive_payload_client_scZaUWx3Nz,
  unhead_ZCwzV2BUil,
  router_gse8FE6AVk,
  _0_siteConfig_fIiXlksfSe,
  payload_client_QICp2vvHbx,
  navigation_repaint_client_BRJ5Fr170B,
  check_outdated_build_client_LTsCME5eDr,
  chunk_reload_client_NREDdPVrPj,
  plugin_vue3_jSmnvxCknW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UnCDV6m3hT,
  plugin_Qqx7zxMt5s,
  nuxt_plugin_mmuokmx30g,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_g82ZZPjvKG,
  plugin_kjBwD4n3Dq,
  switch_locale_path_ssr_nRxBUrOLuB,
  i18n_IXfR5wyQPD,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et
]