import { default as associazionejUMgtUAzfJMeta } from "/opt/build/repo/pages/associazione.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexDFwdTcvy1WMeta } from "/opt/build/repo/pages/infanzia/[slug]/index.vue?macro=true";
import { default as iscrizioni11HDIqaSr3Meta } from "/opt/build/repo/pages/iscrizioni.vue?macro=true";
import { default as istitutoTh4ce84zy0Meta } from "/opt/build/repo/pages/istituto.vue?macro=true";
import { default as modulisticaggmUvUcXLhMeta } from "/opt/build/repo/pages/modulistica.vue?macro=true";
import { default as indexX5IBCmX5PdMeta } from "/opt/build/repo/pages/notizie/[slug]/index.vue?macro=true";
import { default as indexH8qBVUBeFGMeta } from "/opt/build/repo/pages/notizie/index.vue?macro=true";
import { default as indexZzPWgSTvLPMeta } from "/opt/build/repo/pages/primaria/[slug]/index.vue?macro=true";
import { default as component_45stubFade1Ud5g3Meta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubFade1Ud5g3 } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6qlhljyjhyn5hdvwismxjpczfy/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "associazione___it",
    path: "/associazione",
    meta: associazionejUMgtUAzfJMeta || {},
    component: () => import("/opt/build/repo/pages/associazione.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "infanzia-slug___it",
    path: "/infanzia/:slug()",
    meta: indexDFwdTcvy1WMeta || {},
    component: () => import("/opt/build/repo/pages/infanzia/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "iscrizioni___it",
    path: "/iscrizioni",
    meta: iscrizioni11HDIqaSr3Meta || {},
    component: () => import("/opt/build/repo/pages/iscrizioni.vue").then(m => m.default || m)
  },
  {
    name: "istituto___it",
    path: "/istituto",
    meta: istitutoTh4ce84zy0Meta || {},
    component: () => import("/opt/build/repo/pages/istituto.vue").then(m => m.default || m)
  },
  {
    name: "modulistica___it",
    path: "/modulistica",
    meta: modulisticaggmUvUcXLhMeta || {},
    component: () => import("/opt/build/repo/pages/modulistica.vue").then(m => m.default || m)
  },
  {
    name: "notizie-slug___it",
    path: "/notizie/:slug()",
    meta: indexX5IBCmX5PdMeta || {},
    component: () => import("/opt/build/repo/pages/notizie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "notizie___it",
    path: "/notizie",
    meta: indexH8qBVUBeFGMeta || {},
    component: () => import("/opt/build/repo/pages/notizie/index.vue").then(m => m.default || m)
  },
  {
    name: "primaria-slug___it",
    path: "/primaria/:slug()",
    meta: indexZzPWgSTvLPMeta || {},
    component: () => import("/opt/build/repo/pages/primaria/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sphome",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sihome",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/hpcontattaci",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/hpdovesiamo",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/hpchisiamo",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/aggenitori",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/siambienti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sp18orari",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/aggallery",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sptesti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/spclassi",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/si-22-repubblica",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/si-21-diritti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/si-22-pre-requisiti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/si18eventi",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/hpiscrizioni",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/spprogetti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/spmodulistica",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/spambienti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/spdocenti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/spmensa",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sp17progetti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sp20news",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sp18news",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sp17news",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/si19eventi",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sp19news",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/spstoriconews2017",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/ag17progetti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/agstatuto",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sidocenti",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/hpmaddalena",
    component: component_45stubFade1Ud5g3
  },
  {
    name: component_45stubFade1Ud5g3Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubFade1Ud5g3
  }
]